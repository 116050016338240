import React from 'react'
import {useMediaQuery} from 'react-responsive'
import Navigation from './navigation.jsx'
import NavigationMobile from './mobile/navigationMobile.jsx'
import JDPic from './resources/JDProfile.png'

let AboutMe = () =>{
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 })

  return(
    <div >
      {isDesktopOrLaptop ? <Navigation/> : <NavigationMobile/>}
      <div className='container'>
        <img src={JDPic} style={{width: "30%", opacity: "50%"}} alt="I promise to get a better shot soon"/>
        <div className="projectInfo">
        <p>
          A homebody of a kid, I always found myself more interested with video games and computers than anything else. I spent school daydreaming about making a game or program, doodling in my notebook, or even writing stories about my Monster Hunter character. I always had an urge to create, but living in a smaller town didn't afford me many opportunities to chase that desire. My first real taste of what I wanted to do came from coding and drawing a simple educational game in a college game-design course.
        </p>
        <p>
          Years later, I'd end up moving to Portland to pursue a computer science degree, but schooling was expensive, and working fulltime as a sushi chef just to pay those bills ended up burning me out. It ended up being a coworker of mine who suggested Hack Reactor, an intensive fullstack software engineer bootcamp to me, and when the pandemic force me out of a job, I took my chances.
        </p>
        <p>
          I learned a lot in those 14 weeks of nonstop coding. About myself, and coding in general. The biggest focus of the course was on working well as a team, and I think watching other people code in their own way is endlessly interesting and inspiring. Now I want to take what I've learned and grow further, as part of a team. I'm confident that I can bring something new to the table.
        </p>
        <div style={{textAlign: "start"}}>
          <a className="externalLink" href="https://www.linkedin.com/in/joseph-corral/">Hit me up on my Linkedin page</a>
          <br/>
          <br/>
          <a className="externalLink" href="mailto: JDCorral0423@gmail.com">Or Just Send Me an Email!</a>
        </div>
        </div>
      </div>
    </div>
  )
}

export default AboutMe;