import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'
import {useSpring, animated} from 'react-spring'

let Component1Mobile = (props) => {
  const [styles, api] = useSpring(() => ({
    from: { opacity: 0}
  }))

  useEffect(() => {
    api({
      opacity: 1,
      delay: props.time
    })
  }, [])

  return(
    <animated.div
    style={{
      ...styles,
    }}className="projectBoxMobile">
      <img className="projectMobile" src={props.picture} alt="hi"/>
      <div>
        <Link className="gitLinkMobile" to={props.link}>{props.linkName}</Link>
        <h2>{props.blurb}</h2>
      </div>
    </animated.div>
  )



}

export default Component1Mobile