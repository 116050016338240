import React,  {useState, useEffect} from 'react';
import { Link } from 'react-router-dom'
import {useMediaQuery} from 'react-responsive'

const useMousePosition = () => {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 })

  const [
    mousePosition,
    setMousePosition
  ] = React.useState({ x: null, y: null });
  React.useEffect(() => {

    const updateMousePosition = ev => {
      setMousePosition({ x: ev.clientX , y: ev.clientY });
    };
    window.addEventListener('mousemove', updateMousePosition);





    return () => {
      window.removeEventListener('mousemove', updateMousePosition);
    };
  }, []);
  return mousePosition;
};



let Navigation = (props) =>{
  const mousePosition = useMousePosition();
  const [yIndex, setYIndex] = useState(0);
  const y = yIndex ? mousePosition.y + yIndex : mousePosition.y

  useEffect(() => {
    const handleScroll = event => {
      setYIndex(window.scrollY)
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [])


  return(
    <div>
      <div className="crosshairH" style={{top: y}}></div>
      <div className="crosshairV" style={{left: mousePosition.x}}></div>
      <div className="navigationBar">
      <nav
        style={{
          borderBottom: "solid 1px",
          zIndex: "1"
        }}
      >
        <Link className="link" to="/">Home</Link> |{" "}
        <Link className="link" to="/aboutme">About Me</Link>
      </nav>
      </div>
    </div>
  )
}

export default Navigation;