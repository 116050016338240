import React from 'react'
import {useMediaQuery} from 'react-responsive'
import Navigation from './navigation.jsx'
import NavigationMobile from './mobile/navigationMobile.jsx'
import Wordle from './resources/Wordle.png'

let WordleAssistant = () =>{
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 })

  return(
    <div >
      {isDesktopOrLaptop ? <Navigation/> : <NavigationMobile/>}
      <div className='container'>
        <img src={Wordle} className="bigPic" alt="Wordle Assistant"/>
        <div className="projectInfo">
          <h1 style={{fontFamily: "Times New Roman", color: "white", textAlign: "center"} }>An Assistant for the Daily Wordle</h1>
          <p>
            This Wordle Assistant is still in the process of being tuned-up, both stylistically and in its actual logic, but it was one of the first projects I made entirely by myself after graduating from Hack Reactor, for no reasons other than that I wanted to see if I could. It allows you to enter a word, state what color the box was for each letter, and then returns a list of potential guesses based off your answers. It also now has a query function that allows you to check for suggestions before making your guess, and you can even use question marks as wildcards in your query.
          </p>
          <br/>
          <h1 style={{fontFamily: "Times New Roman", color: "white", textAlign: "center"} }>What It's Mad Of</h1>
          <p>
            The assistant is made with nothing but Javascript, Node.js, React.js, Express.js, and Axios.js. Axios is used for querying the datamuse API, which I primarily selected for quick-access and it's wild-card feature.  The main issues being that it doesn't have a way to exclude specific letters from the queries, and some of the lower-scored results tend to be words that would most-likely not be accepted by Wordle.
          </p>
          <br/>
          <h1 style={{fontFamily: "Times New Roman", color: "white", textAlign: "center"} }>What I Learned</h1>
          <p>
            Making a project with no outer guidance or influence was a truly unique experience after getting out of Hack Reactor. When I made my first query, and saw those suggestions pop-up, it was something I had done all on my own.
          </p>
        </div>
        <a href="https://github.com/JCore125/WordleAssistant" className="link">Github Link</a>
      </div>
    </div>
  )
}

export default WordleAssistant;