import React, {useState} from 'react';
import {useSpring, animated} from 'react-spring'
import MediaQuery, {useMediaQuery} from 'react-responsive'
import './App.css'
import Navigation from './navigation.jsx'
import NavigationMobile from './mobile/navigationMobile.jsx'
import Component1 from './component1.jsx'
import Component1Mobile from './mobile/component1Mobile'
import Monet from './resources/MonetGif.gif'
import Infinity from './resources/FrontEnd.gif'
import Wordle from './resources/Wordle.png'


let buttonOn = (e) => {
  e.target.style.background = 'rgb(36, 166, 86)';
  e.target.style.color = 'white'
}

let buttonOff = (e) => {
  e.target.style.background = 'rgb(23, 23, 67)';
  e.target.style.color = 'rgb(36, 166, 86)'
}




let App = () => {

  const [expand, setExpand] = useState(false)
  const props = useSpring({to:{opacity:1}, from:{opacity:0}} )
  const secondProps = useSpring({to:{opacity:1}, from:{opacity:0}, delay:800} )
  const thirdProps = useSpring({to:{opacity:1}, from:{opacity:0}, delay:1600} )
  const buttonProps = useSpring({to:{opacity:1}, from:{opacity:0}, delay:2400} )
  const rectangleProps = useSpring({marginLeft: expand ? 0 : -3000})





  return(
    <div onScroll={(e) => {console.log(e)}}>
      <MediaQuery minWidth={1224}>
        <Navigation/>
        <animated.div style={rectangleProps}className="verticalVisible"></animated.div>

        <div className="container">

          {!expand ? <animated.h1 style={props}className="intro">
            Hi there! My name's JD.
          </animated.h1> : <animated.button className="button" style={buttonProps}onClick={() => {setExpand(!expand)}} onMouseOver={(e) => {buttonOn(e)}}onMouseLeave={(e) => {buttonOff(e)}}>Reset</animated.button>}
          {!expand ? <animated.h2 className="intro2" style={secondProps}>
             A nerd and an introvert, I enjoy building things with creative coding.
          </animated.h2> : <div/>}
          {!expand ? <animated.h3 className="intro3"style={thirdProps}>
            If you'd like to see some of the projects I've worked on, just click the button below.
          </animated.h3> : <div></div>}
          {!expand ? <animated.button className="button" style={buttonProps}onClick={() => {setExpand(!expand)}} onMouseOver={(e) => {buttonOn(e)}}onMouseLeave={(e) => {buttonOff(e)}}>Click Me!</animated.button> : <div/>}
        </div>
        <div className="projectsContainer">
          {expand ? <Component1 time={1000}picture={Monet} link='/monet' linkName='Fraud Monet' blurb="A Browser-Based Multiplayer Drawing Game"/> : <span></span>}
          {expand ? <Component1 time={1500}picture={Infinity} link='/infinity' linkName="To Infinity and Beyond" blurb="The Frontend for an E-Commerce Site"/> : <span></span>}
          {expand ? <Component1 time={2000}picture={Wordle} link='/wordle' linkName="My Wordle Assistant" blurb="A Wordle Helper to get an Edge Over Your Friends"/> : <span></span>}
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={1224}>
      <NavigationMobile/>
        <animated.div style={rectangleProps}className="verticalVisibleMobile"></animated.div>

        <div className="containerMobile">

          {!expand ? <animated.h1 style={props}className="introMobile">
            Hi there! My name's JD.
          </animated.h1> : <animated.button className="buttonMobile" style={buttonProps}onClick={() => {setExpand(!expand)}} onMouseOver={(e) => {buttonOn(e)}}onMouseLeave={(e) => {buttonOff(e)}}>Reset</animated.button>}
          {!expand ? <animated.h2 className="introMobile2" style={secondProps}>
            A nerd and an introvert, I enjoy building things with creative coding.
          </animated.h2> : <div/>}
          {!expand ? <animated.h3 className="introMobile3"style={thirdProps}>
            If you'd like to see some of the projects I've worked on, just click the button below.
          </animated.h3> : <div></div>}
          {!expand ? <animated.button className="buttonMobile" style={buttonProps}onClick={() => {setExpand(!expand)}} onMouseOver={(e) => {buttonOn(e)}}onMouseLeave={(e) => {buttonOff(e)}}>Click Me!</animated.button> : <div/>}
        </div>
        <div className="projectsContainerMobile">
          {expand ? <Component1Mobile time={1000}picture={Monet} link='/monet' linkName="Fraud Monet" blurb="A Browser-Based Multiplayer Drawing Game"/> : <span></span>}
          {expand ? <Component1Mobile time={1500}picture={Infinity} link='/infinity' linkName="To Infinity and Beyond" blurb="The Frontend for an E-Commerce Site"/> : <span></span>}
          {expand ? <Component1Mobile time={2000}picture={Wordle} link='/wordle' linkName="My Wordle Assistant" blurb="A Wordle Helper to get an Edge Over Your Friends"/> : <span></span>}
        </div>
      </MediaQuery>


    </div>


  )
}

export default App;