import React from 'react'
import Navigation from './navigation.jsx'
import NavigationMobile from './mobile/navigationMobile'
import MonetGif from './resources/MonetGif.gif'
import {useMediaQuery} from 'react-responsive'

let Monet = () =>{
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 })
  return(
    <div >
      {isDesktopOrLaptop ? <Navigation/> : <NavigationMobile/>}
      <div className='container'>
      <img src={MonetGif} alt='Fraud Monet Example'/>
        <div className="projectInfo">
          <br/>
          <h1 style={{fontFamily: "Times New Roman", color: "white", textAlign: "center"} } >A Browser-based Multiplayer Game Featuring Private Rooms.</h1>
          <br></br>
          <p>
            Fraud Monet is the the largest and most formative project I've worked on in my career as a software Engineer so far, with 8 other engineers. My personal responsibility was on the styling and functionality of the landing page, though I also worked with others on some of the game logic and connectivity.
          </p>
          <br/>
          <h1 style={{fontFamily: "Times New Roman", color: "white", textAlign: "center"} } >How It's Played</h1>
          <p>
            The game has a shared canvas that players each take turn drawing on with their own assigned color. A category and prompt for each round, the latter being hidden from the "fraud". A voting system where each player can pick who they believe was the fraud. A live text chat for communication between players. A spectator position for any user who joins the room after the initial 10. Each time a game is created, it is given a link that can be distributed for other users to join that specific room. Multiple instances of the game can be running at the same time.
          </p>
          <br/>
          <h1 style={{fontFamily: "Times New Roman", color: "white", textAlign: "center"} } >Tech Stack</h1>
          <p>
            The site was designed and deployed with Node.js and React, p5.js was used for the canvas/drawing component, Socket.io and Redis were used to allow real-time games with multiple connections as well as randomly generated rooms that could exist simultaneously and be accessed by a link. I was lucky to already have some experience with Socket.io, as I had used it in a one-day MVP where I had made a multiplayer platformer game with it and Phaser.js just a short while before.
          </p>
          <br/>
          <h1 style={{fontFamily: "Times New Roman", color: "white", textAlign: "center"} } >What I learned</h1>
          <p>
            This was by far the largest team I had worked with yet, and I really learned a lot about how important it is to make sure that people are organized and on the same page as each other. I also learned a lot from our interactions with the client, such as the importance of a good mockup, and the need for some pushback when negotiating the app's necessary features.
          </p>
          <br/>
          <a href="https://github.com/TeamVaporeon/fraud-monet" className="link">Github Link</a>
        </div>
      </div>
    </div>
  )
}

export default Monet;