import React from 'react'
import {useMediaQuery} from 'react-responsive'
import Navigation from './navigation.jsx'
import NavigationMobile from './mobile/navigationMobile.jsx'
import Infinity from './resources/FrontEnd.gif'

let FrontEnd = () =>{
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 })

  return(
    <div >
      {isDesktopOrLaptop ? <Navigation/> : <NavigationMobile/>}
      <div className='projectPage'>
        <img className="image" src={Infinity} alt='Fraud Monet Example'/>
        <div className="projectInfo">
          <h1 style={{fontFamily: "Times New Roman", color: "white", textAlign: "center"} }>The Storefront for an E-commerce Site</h1>
          <p>
              This was the first project I'd ever worked on with a team, and I learned a lot alongside these 3 other software engineers. The site is divided into 4 major components: an overview, related items and personal outfits, questions and answers, and reviews for the product. Clicking on any product causes every other component to change along with the overview. I personally worked on the related items and outfits component.
          </p>
          <br/>
          <h1 style={{fontFamily: "Times New Roman", color: "white", textAlign: "center"} }>What It's Made Of</h1>
          <p>
            The site itself is pretty straightforward. It's made with Express.js, React.js, and Node.js, and styled with vanilla CSS. It's divided into four major components, and they all work together using React's Context feature, allowing all of the other components to update when the main product overview changes.
          </p>
          <br/>
          <h1 style={{fontFamily: "Times New Roman", color: "white", textAlign: "center"} }>What I Learned</h1>
          <p>
            As I mentioned before, this was my first time working with a team, so I learned a lot about proper workflow using the Agile methodology. We had stand up meetings every morning, filled out Trello tickets, worked in the same Zoom room, and then had stand downs at the end of the day. It was a lot of involvement, and honestly, I loved every minute of it. It really helped that a couple of my coworkers were HTML masters. I learned so much about how to think about CSS stylings during that period.
          </p>
          <br/>
        </div>

        <a href="https://github.com/hr-rfp57-team-buzz/project-catwalk" className="link">Github Link</a>
      </div>
    </div>
  )
}

export default FrontEnd;